.main_container {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding-top: 80px;
  padding-bottom: 60px;
  height: 100vh;
  overflow: scroll !important;
  background: #fff;
}

.container {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  /* margin-top: -100px; */
  /* width: 100%;
    justify-content: center;
    align-items: center;  */
  /* margin-bottom: 100px;  */
  padding-top: 30px;
  background: #fff;
  padding-bottom: 100px;
  width: 100%;
}

.btn {
  background: #26a3db;
  color: #fff;
  text-transform: uppercase;
  width: 230px;
  height: 40px;
  border: solid 1px transparent;
  border-radius: 5px;
  cursor: pointer;
  transition: 0.9s;
  margin-top: 10px;
}

.btn:hover {
  /* opacity: 0.7; */
  background: #00b3ff;
}
.webcam {
  /* margin-top: 30px; */
  animation: pulse 2s ease-out infinite;
  position: relative;
}
.webcam::after {
  content: "";
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1;
  border-radius: 50%;
  position: absolute;
}
@keyframes pulse {
  0% {
    box-shadow: 0 0 0 0px rgba(0, 210, 255, 1),
      0 0 0 0px rgba(0, 210, 255, 0.85);
  }
  50% {
    transform: scale(0.95);
  }
  100% {
    box-shadow: 0 0 0 15px rgba(0, 210, 255, 0), 0 0 0 30px rgba(0, 210, 255, 0);
  }
}
.cam_container {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  margin-left: 20%;
  /* overflow-y: scroll; */
}

.instructions_container {
  width: 50%;
  height: 100%;
  /* height: 100%; */
  -webkit-box-shadow: 1px 1px 22px -7px rgba(191, 191, 191, 1);
  -moz-box-shadow: 1px 1px 22px -7px rgba(191, 191, 191, 1);
  box-shadow: 1px 1px 22px -7px rgba(191, 191, 191, 1);

  border-radius: 10px;
  padding: 10px;
  margin-right: 3%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
}
.instructions_container > h3 {
  font-weight: 500;
  /* text-transform: uppercase; */
  color: #111;
}
.instructions_container ul {
  color: #111;
  padding-bottom: 20px;
  margin-left: 20px;
}
.instructions_container ul li {
  color: #111;
  font-family: sans-serif;
  list-style-type: disc !important;
  
}
.instructions_container img {
  width: 80px;
}
.sign {
  width: 60% !important;
}
.images_container {
  display: flex;
  justify-content: center;
  align-items: space-between;
  width: 100%;
}
.cont {
  width: 33%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.success_circle {
  border: solid 6px #26a3db;
  border-radius: 50%;
  height: 50%;
  width: 55%;
}

.pending{
  border-radius: 50%;
  height: 50%;
  width: 55%;
}
.success_msg,.pending_msg {
  padding-top: 30px;
  font-size: 1.2rem;
  font-family: sans-serif;
  text-transform: uppercase;
  color: #00b03b;
  font-family: 500;
  animation-name: fade-come;
  animation-duration: 1.5s;
  animation-timing-function: ease;
  animation-iteration-count: infinite;   
  margin: 0;
}
   
.pending_msg{
color: #111;
font-weight: 600;
}
@keyframes fade-come {
  0% {
    opacity: 1;
  }

  50% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

.btn1 {
  text-align: center;
  margin: 12px auto;
  width: 80%;
}

.errormessage {
  font-size: 0.8rem;
  margin: 10px 0 0 0;
  text-align: center;
  color: red;
}

.info_rejected {
  color: red;
  font-size: 0.9rem;
  margin: 0;
  /* background-color: #fff; */
}
