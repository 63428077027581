/*-----------------------------------------
------------- ( team_con )
-----------------------------------------*/
.container-flex {
  position: fixed;
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
}
.react-switch {
  vertical-align: middle;
  margin-left: 4px;
}
.toggle-label {
  background-color: #18bfae33;
  color: #141313d1;
  border-radius: 25px;
  padding: 5px 10px;
  font-weight: 500;
  font-size: 13px;
  cursor: pointer;
  user-select: none;
}
.toggle-enabled {
  background-color: #31bf1833;
}
.scroll {
  overflow-y: scroll;
  height: inherit;
}
/* .header-container {
  height: 25%;
} */
.team_con {
  background: #f9f9fb;
  padding: 15px;
}
.search {
  padding: 15px;
}
.search input {
  border: none;
  width: 300px;
  border-radius: 5px;
  padding: 8px 15px;
  padding-left: 40px;
  background: url(../../assets/images/search.svg) #f2f2f8 no-repeat;
  background-position: center left 10px;
}

.team_con a {
  display: inline;
  text-decoration: none;
}

.team_con ul {
  flex-wrap: wrap;
  display: flex;
}

.team_con ul li {
  list-style-type: none;
  padding: 15px;
  flex: 0 0 33.3333%;
  max-width: 33%;
}
.team_con .box {
  border-radius: 10px;
  position: relative;
  padding: 15px 20px;
  height: 192.5px;
  transition: box-shadow 0.3s !important;
}

.team_con .box:hover {
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}

.team_con .box .line {
  margin: 60px 0 5px;
  background: #000;
  height: 1px;
  opacity: 0.1;
}
.team_con .box h4 {
  font-weight: 600;
  letter-spacing: 1px;
  text-transform: uppercase;
}
.team_con .img_usr {
  display: flex;
  align-items: center;
}

.team_con .vcm {
  background: rgb(0 0 0 / 0.1);
  position: absolute;
  border-radius: 5px;
  padding: 2px 3px;
  bottom: 25px;
  right: 20px;
}
.team_con .vcm span {
  padding: 0 10px;
}
.team_con .vcm span:nth-child(1),
.team_con .vcm span:hover {
  border-radius: 4px;
  background: #fff;
}

.team_con .box.add {
  border: 2px dashed #c4c4c4;
  background: #fff;
  text-align: center;
  justify-content: center;
  align-items: center;
  display: flex;
}
.team_con .box.add .center img {
  width: 50px;
  margin-bottom: 15px;
}

.all_supervisor_modal {
  display: flex;
  justify-content: space-around;
}

.all_supervisor_modal > div {
  text-align: center;
  width: 50%;
}

.button_supervisor {
  background-color: #26a3db;
  color: white;
  width: 13rem;
  padding: 5px 0;
  font-weight: bold;
  font-size: 1.1em;
  border-radius: 10px;
  text-align: center;
}

.modal_body_supervisor::-webkit-scrollbar {
  display: none; /* for Chrome, Safari, and Opera */
}

.modal_body_supervisor {
  height: 10rem;
  overflow-y: scroll;
  display: flex;
  flex-direction: column;
  -ms-overflow-style: none; /* for Internet Explorer, Edge */
  scrollbar-width: none;
}

@media (max-width: 1024px) {
  .team_con ul li {
    flex: 0 0 50%;
    max-width: 50%;
  }
}

@media (max-width: 768px) {
  .search input {
    width: 250px;
  }
  .team_con ul li {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .team_con .vcm a {
    padding: 0 6px;
  }
  .team_con .box .line {
    margin: 40px 0 20px;
  }
  .team_con {
    padding: 10px 0 0;
  }
  .search {
    padding-bottom: 0;
  }
}
@media (max-width: 575px) {
  .search input {
    width: 100%;
  }
}

.dashboard-avatar {
  width: 35px;
  height: 35px;
  background: #26a3db;
  border-radius: 10px;
  color: white;
  margin-right: 10px;
  position: relative;
}

.filters-container {
  text-align: center;
}

.dashboard_container {
  z-index: 1000;
}
