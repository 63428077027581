.audio-progress {
  display: flex;
  align-items: center;
}

.meter-container {
  display: inline-block;
  background: -webkit-linear-gradient(#e21d1d 59%, #ff9d00 70%);
  margin: auto;
  width: 10px;
  margin: 1px;
  border-radius: 10px;
}

.meter-2 {
  background: -webkit-linear-gradient(
    #f2ea00 37%,
    #52b152 59%,
    #52b152 59%,
    #008a00 79%
  );
}

.meter-3 {
  background: -webkit-linear-gradient(
    #fff200 22%,
    #f2ea00 37%,
    #52b152 59%,
    #52b152 59%,
    #008a00 79%
  );
}

.meter-4 {
  background: -webkit-linear-gradient(
    #ff9d00 12%,
    #fff200 22%,
    #f2ea00 37%,
    #52b152 59%,
    #52b152 59%,
    #008a00 79%
  );
}

.meter-5 {
  background: -webkit-linear-gradient(
    #e21d1d 14%,
    #ff9d00 17%,
    #fff200 22%,
    #f2ea00 37%,
    #52b152 59%,
    #52b152 59%,
    #008a00 79%
  );
}
