body {
  --light: hsl(220, 50%, 90%);
  --primary: hsl(255, 30%, 55%);
  --focus: hsl(210, 90%, 50%);
  --border-color: hsla(0, 0%, 100%, 0.2);
  --global-background: hsl(220, 25%, 10%);
  --background: linear-gradient(
    to bottom,
    hsl(210, 30%, 20%),
    hsl(199, 72%, 50%)
  );
  --shadow-1: hsla(236, 50%, 50%, 0.3);
  --shadow-2: hsla(236, 50%, 50%, 0.4);
}

.modal_call {
  padding: 1rem;
  bottom: 50%;
  right: 55%;
  background: #27a0da;
  border-radius: 5px;
  color: var(--light);
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  position: absolute;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
/* 
.modal__title {
  font-size: 1.6rem;
  text-align: center;
} */

.modal__text {
  /* padding: 0 1rem; */
  text-align: center;
  font-size: 1rem;
}

.modal_first_letter {
  width: 5rem;
  height: 5rem;
  background-color: #6b6435;
  border-radius: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.modal_first_letter > p {
  font-size: 3rem;
  font-weight: bold;
}

.modal_btn_container {
  display: flex;
  justify-content: space-around;
  width: 12rem;
  height: 7rem;
  align-items: center;
}

.modal__btn {
  border: 1px solid var(--border-color);
  border-radius: 100%;
  width: 3rem;
  height: 3rem;
  color: inherit;
  background: transparent;
  font-size: 0.9rem;
  font-family: inherit;
  letter-spacing: 0.2rem;
  transition: 0.2s;
  cursor: pointer;
}

.modal__btn:hover,
.modal__btn:focus {
  background: var(--focus);
  border-color: var(--focus);
  transform: translateY(-0.2rem);
}

.link-2 {
  width: 2rem;
  height: 2rem;
  border: 1px solid var(--border-color);
  border-radius: 100rem;
  text-decoration: none;
  color: inherit;
  font-size: 1.7rem;

  position: absolute;
  top: 0.5rem;
  right: 0.5rem;

  display: flex;
  justify-content: center;
  align-items: center;

  transition: 0.2s;
}

.link-2::before {
  content: "×";

  transform: translateY(-0.1rem);
}

.link-2:hover,
.link-2:focus {
  background: var(--focus);
  border-color: var(--focus);
  transform: translateY(-0.2rem);
}
