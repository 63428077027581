video {
  display: block;
  position: relative;
  width: 100%;
  height: 100%;
  border-radius: 10px;
}

.student-screen-inst-video {
  max-height: 95%;
}
