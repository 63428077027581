.container {
  display: flex;
  justify-content: space-around;
  align-items: center;
  background: #fff;
  border: solid 1px #ccc;
  
  overflow: auto;
  padding: 30px;
  border-radius: 5px;
  width: 100%;
}
.left_container {
  width: 20%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.left_container p{
  color: #111;
  font-size: 0.9rem;
  margin-top: 10px;
}
.right_container {
  width: 80%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.right_container h2{
  color: #111;
  font-weight: 500;
  text-transform: uppercase;
  margin-bottom: 10px;
  font-size: 1.5rem;
}
.right_container h4{ 
  color: #111;
  margin-top: 10px;
  font-family: sans-serif;
  font-weight: 500;
  font-size: 1.2rem;
}

.right_container p{
  font-size: 1rem;
  font-family: sans-serif;
  color:#111;
}
.selector_container {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  margin: 20px 0;
}

.selector_container > div {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  
  color: #111;
}
.selector_container > div > input{
    width: 10%;
}

.selector_container > div > p{
    margin: 0;
    width: 225px;
}

.submit_btn{
  padding: 10px 20px;
  width: 200px;
  font-size: 1rem;
  background: #26a3db;
  color: #fff;
  border-radius: 5px;
  border: solid 1px transparent;
  cursor: pointer;

}


