.container {
  position: relative;
  overflow: scroll;
}

/* Hide the images by default */

/* Add a pointer when hovering over the thumbnail images */
.cursor {
  cursor: pointer;
}

/* Container for image text */
.caption-container {
  text-align: center;
  background-color: #222;
  padding: 2px 16px;
  color: white;
}

.row {
  display: flex;
  flex-direction: row;
  width: 100%;
  flex-wrap: wrap;
  /*height: 100px;*/
  overflow-y: scroll;
  margin: 10px 0;
}

/* Six columns side by side */
.column {
  width: 25%;
}

/* Add a transparency effect for thumnbail images */
.demo {
  opacity: 1;
}

.active,
.demo:hover {
  opacity: 0.6;
}

.modal-backdrop.show{
  opacity: 0.2 !important;
}