.Download_app_page{
    width: 100%;
    height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.download_header{
    margin-bottom: 20px;
}

.download_form{
    display: flex;
    width:30%;
    justify-content: space-around;
    margin-top: 20px;
}

.download_form > .link{
    width:30%;
}


