.fileInput {
  border-bottom: 4px solid lightgray;
  border-right: 4px solid lightgray;
  border-top: 1px solid black;
  border-left: 1px solid black;
  padding: 10px;
  margin: 15px;
  cursor: pointer;
}

.previewComponent {
  display: flex;
  align-items: center;
  flex-direction: column;
}

.upload_file_container {
  display: flex;
  align-items: center;
}

.submitButton {
  padding: 12px;
  margin: 10px;
  background: white;
  border: 4px solid lightgray;
  border-radius: 15px;
  font-weight: 700;
  font-size: 10pt;
  cursor: pointer;
  display: block;
}

.submitButton:hover {
  background: #efefef;
}
