:root {
  --selected-icon-bg-color: #dfdfdf;
}

body {
  position: relative;
  margin: 0px;
  height: calc(var(--vh, 1vh) * 100);
  width: 100%;
  overflow: hidden;
  font-family: "Helvetica Neue Light", "HelveticaNeue-Light", "Helvetica Neue",
    Calibri, Helvetica, Arial, sans-serif;
}

#whiteboardContainer {
  height: calc(var(--vh, 1vh) * 100);
  width: 100%;
}

.btn-group button {
  background: transparent;
  border: 2px solid #636060;
  margin: -1px;
  /* Green border */
  color: black;
  /* White text */
  padding: 11px 14px;
  /* Some padding */
  cursor: pointer;
  /* Pointer/hand icon */
  float: left;
  /* Float the buttons side by side */
}

@media (max-device-width: 1024px) {
  .btn-group button {
    font-size: 3em;
    height: 135px;
    width: 150px;
  }

  .minGroup {
    width: 80px;
  }
}

@media (min-device-width: 1024px) {
  .btn-group button {
    font-size: 1.2em;
    height: 45px;
    width: 50px;
  }

  .minGroup {
    width: 25px;
  }
}

button::-moz-focus-inner {
  border: 0;
}

.whiteboard-edit-group.group-disabled {
  background: repeating-linear-gradient(
    45deg,
    rgba(255, 166, 0, 0.366),
    rgba(255, 166, 0, 0.366) 10px,
    rgba(255, 166, 0, 0.666) 10px,
    rgba(255, 166, 0, 0.666) 20px
  );
}

/*
 * Deactivate all pointer events on all the children
 * of a group when it's disabled.
 */
.whiteboard-edit-group.group-disabled > * {
  pointer-events: none;
}

/* Clear floats (clearfix hack) */

.btn-group:after {
  content: "";
  clear: both;
  display: table;
}

/* Add a background color on hover */

.btn-group button:hover {
  background-color: #9a9a9a;
}

button {
  outline-width: 0;
}

.btn-group {
  background-color: #808080ab;
  margin-left: 5px;
  margin-bottom: 5px;
  float: left;
  position: relative;
}

.whiteboard-tool.active:not(:disabled) {
  background: var(--selected-icon-bg-color);
}

#whiteboardThicknessSlider {
  -webkit-appearance: none;
  width: 100%;
  height: 10px;
  border-radius: 3px;
  background: transparent;
  outline: none;
  opacity: 1;
  -webkit-transition: opacity 0.15s ease-in-out;
  transition: opacity 0.15s ease-in-out;
}

.textBox.active {
  border: 1px dashed gray;
}

.textBox > .removeIcon,
.textBox > .moveIcon {
  display: none;
}

.textBox.active > .removeIcon,
.textBox.active > .moveIcon {
  display: block;
}

.stickyNote {
  box-shadow: 5px 5px 7px rgba(33, 33, 33, 0.7);
  overflow-y: scroll;
}

.stickyNote::-webkit-scrollbar {
  display: none;
}

.modalBtn {
  padding: 5px;
  border-radius: 5px;
  border: 0px;
  min-width: 50px;
  cursor: pointer;
}

#displayWhiteboardInfoBtn.active {
  background: var(--selected-icon-bg-color);
}

#whiteboardInfoContainer {
  position: absolute;
  bottom: 10px;
  right: 10px;
}

.displayNone {
  display: none;
}

#shareWhiteboardDialog {
  width: 100vw;
  height: 100vh;
  background-color: rgba(1, 1, 1, 0.35);
  z-index: 10000000000000;
  position: absolute;
  top: 0;
  left: 0;
}

#shareWhiteboardDialogMessage {
  background-color: lightgreen;
  padding: 20px;
  font-weight: bold;
}

.shareWhiteboardDialogContent {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: 100%;
  height: 100%;
}

.shareWhiteboardDialogItem {
  padding: 5px;
  margin: 5px;
}
