.text {
  display: flex;
  flex-wrap: wrap;
}

.text h6,
.text p {
  margin-bottom: 0;
  flex: 0 0 100%;
  font-weight: 600;
  font-size: 12px;
  line-height: 20px;
}
.imgContainer {
  display: flex;
  justify-content: space-between;
  height: 60px;
}

.imgContainer > h4{
  font-size: 1.1rem;
  width: 55%;
}
.imgSize {
  width: 60px;
  height: 60px;
  border-radius: 10px;
}

#countdown {
  width: 10rem;
}

.countdown_ul > .duration_list {
  display: flex;
  flex: 25%;
  flex-direction: column;
  text-align: center;
  font-size: 0.4rem;
  list-style-type: none;
  text-transform: uppercase;
  padding: 0 !important;
}

.duration_list > span {
  display: block;
  font-size: 1.3rem;
  font-weight: bold;
}

.creator_room {
  font-size: 0.8rem;
  font-weight: normal;
  /* display: flex;
  width: 100%; */
}

.creator_room > span {
  font-size: 0.5rem;
}

.roomcardlink {
  cursor: pointer;
  border: 1px solid rgb(16, 13, 52);
  border-radius: 7px;
}

.attendance_main {
  display: flex;
  width: 100%;
}

.attendance_main_inside {
  width: 25%;
  display: flex;
  flex-direction: column;
}

.attendance_main_inside > h6 {
  background-color: rgb(16, 13, 52);
  color: white;
  display: flex;
  justify-content: center;
  margin: 0;
  padding: 2px;
  font-size: 1.1em;
}

.attendance_main_inside > p {
  color: black;
  font-size: 0.9rem;
  display: flex;
  justify-content: center;
  margin: 0;
  padding: 2px;
}
