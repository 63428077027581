.container{
    height: 100vh !important;
    overflow-y: scroll;
}

.heading {
  text-align: center;
  font-family: sans-serif;
  color: #111;
  font-weight: 400 !important;
  margin-top: 40px;
}
.btn_cont {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.select_btn,.verify_btn {
  background-color: #26a3db;
  color: #fff;
  border: solid 1px #26a3db;
  border-radius: 5px;
  width: 200px;
  height: 40px;
  text-transform: uppercase;
  text-align: center;
  font-size: 0.9rem;
  margin-right: 20px;
  margin-top: 20px;
  cursor: pointer;
  
}

.images_container {
  width: 100%;
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  justify-content: center;
  align-items: center;
  /* flex-wrap: wrap; */
  gap: 20px;
  padding: 50px 0;
  padding-bottom: 180px;
  
}

.card {
  /* margin-left: 5%; */
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin-top: 30px;
  position: relative;
}
.card > p {
  font-size: 0.8rem;
  margin-bottom: 5px;
  color: #111;
  font-family: sans-serif;
  text-transform: uppercase;
  position: absolute;
  bottom: 0;
  background-color: #111111e0;
  color: #fff;
  width: 50%;
  text-align: center;

}
.card > img {
  width: 200px;
  height: 170px;
  border-radius: 10px;
  cursor: pointer;

  padding: 2px;
}
.card > button {
  background-color: #26a3db;
  color: #fff;
  border: solid 1px #26a3db;
  border-radius: 5px;
  width: 150px;
  height: 30px;
  text-transform: uppercase;
  text-align: center;
  font-size: 0.7rem;
  margin-top: 10px;
  cursor: pointer;
}

.border {
  border: solid 4px #26a3db;
}

.para_info{
    text-align: center;
    margin:0 auto;
    font-size: 1rem;
    color: #000000;
    font-weight: 500;
    width: 90%;
}
.para_info > span{
    font-weight: 600;
    color: #26a3db;
}