.participant-card {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
}

.participant-details-Instructor {
  display: flex;
  color: #1e3869;
  position: relative;
  height: 100%;
  align-items: center;
}
.participant-details-Supervisor {
  display: flex;
  color: #1e3869;
  position: relative;
  height: 100%;
  align-items: center;
}

.participant-details-Student {
  display: flex;
  color: #1e3869;
  position: relative;
  height: 100%;
  align-items: center;
  color: white;
}
.participant-details-Agent {
  display: flex;
  color: #1e3869;
  position: relative;
  height: 100%;
  align-items: center;
  color: white;
}

.participant-name-type-Student {
  padding: 5px;
}

.participant-name-type-Instructor {
  padding: 5px;
}
.participant-name-type-Agent {
  padding: 5px;
}

.participant-name-type-Supervisor {
  padding: 5px;
}
.participant-name {
  font-size: 14px;
}

.participant-type {
  font-size: 12px;
  margin: 0;
}

.control-icons {
  display: flex;
  background: #ceedfb;
  border-radius: 8px;
  align-items: center;
  margin: 0 auto;
  align-self: center;
  padding: 5px;
  justify-content: space-between;
}

.control-icons li {
  text-align: center;
  flex: 0 0 5%;
  line-height: 35px;
  color: #0e2549;
  cursor: pointer;
  padding: 0 5px;
  border-radius: 8px;
}

.control-icons li:hover {
  background: #fbfbfb;
}

.small-participant-video {
  top: 0;
  left: 0;
  width: 17em;
  height: 10em;
  position: relative;
  overflow: hidden;
  display: block;
}

.none {
  display: none;
}

.small-right-video {
  top: 0;
  width: 17em;
  height: 10em;
  position: relative;
  margin:0 5px;
}

.red {
  color: red;
}

.margin-0-auto {
  margin: 0 auto;
}

.flex {
  display: flex;
}

.height100 {
  height: 100%;
}

.screen-num {
  margin-left: 7px;
  margin-bottom: 3px;
  border-radius: 10px;
  width: 10px;
  height: 10px;
  bottom: -3px;
  background: #fbfbfb;
  font-size: 7px;
  position: absolute;
  justify-content: flex-end;
  align-items: flex-end;
}
/* .screen-two-icon {
} */

#drop_blur {
  background: transparent;
  width: 100%;
  height: 100%;
  margin: 0;
  border: none;
  display: flex;
  align-items: center;
  justify-content: center;
  border: none;
  outline: none;
  text-align: center;
  cursor: pointer;
}

.dropup .dropdown-menu.show {
  background: #9ddbf7;
  border-radius: 10px;
  padding: 2%;
  text-align: center;
}

.message_export_data {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100% !important;
}

.feedback_modal_container {
  border: 0px solid black;
  width: 12% !important;
}

.modal-body {
  margin: 0;
  padding: 0 !important;
}

.filters-container {
  display: flex;
  margin: 0;
  padding: 0;
  width: 100% !important;
}

.feedback_modal_main {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0;
  margin: 0;
}

.modal-content {
  border: 3px solid lightgray !important;
}

.modal_link > .link {
  padding: 5px 5px;
}

.dropdown-content {
  position: absolute;
  background-color: #f9f9f9;
  min-width: 180px;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  z-index: 1;
  max-height: 300px;
  overflow-y: scroll;
  border-radius: 8px;
}

.dropdown-content::-webkit-scrollbar {
  /* WebKit */
  width: 0;
  height: 0;
}
.dropdown-content a {
  float: none;
  text-decoration: none;
  display: block;
  background: #9ddbf7;
  padding: 2%;
  text-align: center;
  margin: 0;
  color: black;
  border-bottom: 1px Solid black;
}

.dropdown-content a:hover {
  background-color: #ddd;
}


.timer{
  margin : 3rem 0;
  width: 100%;
  display: flex;
  height: 12%;
  justify-content: center;
  align-items: center;
}

.digits{
  font-family: Verdana, Geneva, Tahoma, sans-serif;
  font-size: 2rem;
}


