.sec {
  padding: 0px 10px;
  background-color: #f4f4f4;
  transition: 0.5s;
  margin: 7px 10px;
}

.txt {
  vertical-align: top;
  font-size: 0.7rem;
  padding: 0px;
}

.sub {
  font-size: 0.7rem;
  color: grey;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
}

.new {
  border-bottom: 1px dashed rgb(38, 163, 219);
  border-top: 1px dashed rgb(38, 163, 219);
  border-right: 1px dashed rgb(38, 163, 219);
  border-left: 10px solid rgb(38, 163, 219);
  border-radius: 10px;
}

.sec:hover {
  background-color: #bfbfbf;
}

.notification_article {
  padding: 1rem 1rem;
  border: 2px solid #eae6eb;
  margin-bottom: 0.3rem;
  border-radius: 0.25rem;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.1);
  
}
.notification_article h4 {
  text-transform: none;
  line-height: 1.5;
}

.notification_article header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.notification_article header h4 {
  margin-bottom: 0;
}
.btn_noti {
  background: transparent;
  border-color: transparent;
  width: 2rem;
  height: 2rem;
  background: #eae6eb;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  color: rgb(38, 163, 219);
  cursor: pointer;
  margin-left: 1rem;
  align-self: center;
  min-width: 2rem;
}

.notification_article-title {
  cursor: pointer;
  font-size: 1rem;
}

.notification_article_header {
  border: none;
}

.noti_container_overflow {
  height: 100%;
  overflow-y: auto;
  padding: 2px;
}
