button#menu-dropdown {
  width: 100%;
  padding: 5px;
  font-size: inherit;
  border-radius: 2px !important ;
}

.dropdown-inner-container.dropdown-menu.show {
  width: 100%;
  text-align: center;
  max-height: 150px;
  overflow-y: scroll !important;
}

.chat-form > input {
  margin-bottom: 0px !important;
}

.chat-container {
  position: relative;
  height: 100%;
  padding: 5px 0px;
  display: flex;
  justify-content: space-around;
  flex-direction: column;
}

.chat-message-container {
  overflow-y: scroll;
  margin: 5px 0;
  max-height: 90%;
  height: 90%;
  padding: 0 0 10px 0;
}
.chat-message-container::-webkit-scrollbar {
  width: 0 !important;
}
.chat-message-inner-container {
  height: 100%;
}
.my-message {
  padding: 10px;
  margin: 5px 0;
  text-align: right;
}

.others-message {
  padding: 10px;
  display: flex;
}
.name {
  display: inline-block;
}

.mymessage-string {
  padding: 5px 10px;
  border-radius: 10px;
  background: #d3dceb;
  display: inline-block;
  font-weight: 600;
  word-break: break-word;
}
.time-stamp {
  font-size: 8px;
  font-weight: normal !important;
  border-top: 1px solid blanchedalmond;
}
.others-message-string {
  background: #fff;
  padding: 5px 10px;
  border-radius: 10px;
  box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.15);
  font-weight: 600;
  word-break: break-word;
}

.submit-image {
  max-width: 20px;
}

.submit-btn {
  background: #26a3db;
  width: 20%;
  height: 100%;
  border: none;
  text-align: center;
  border-radius: 0 5px 5px 0;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.chat-form {
  display: flex;
  justify-content: center;
  margin-bottom: 0px !important;
  width: 100%;
  height: 40px;
}

.chat-bubble {
  background-color: red;
  border-radius: 50%;
}

.typing {
  height: 3rem;
  display: flex;
  justify-content: center;
  align-items: center;
}

.typing .dot {
  animation: mercuryTypingAnimation 1.5s infinite ease-in-out;
  background-color: #6cad96;
  border-radius: 50%;
  height: 7px;
  margin-right: 4px;
  vertical-align: middle;
  width: 7px;
  display: inline-block;
}
.typing .dot:nth-child(1) {
  animation-delay: 100ms;
}
.typing .dot:nth-child(2) {
  animation-delay: 200ms;
}
.typing .dot:nth-child(3) {
  animation-delay: 300ms;
}
.typing .dot:last-child {
  margin-right: 0;
}

@keyframes mercuryTypingAnimation {
  0% {
    transform: translateY(0px);
    background-color: #6cad96;
  }
  28% {
    transform: translateY(-7px);
    background-color: #9ecab9;
  }
  44% {
    transform: translateY(0px);
    background-color: #b5d9cb;
  }
}

.chat-message-header {
  display: flex;
  justify-content: space-between;
}

.chat-message-header-own {
  display: flex;
  justify-content: flex-end;
}

.chatType {
  color: red;
}

.typer_styles {
  width: 3rem;
  /* position: relative; */
  color: black;
  font-size: 1.2em;
  /* background: rgb(255, 255, 255); */
  /* bottom: 10px; */
}

.chat_textarea {
  overflow-y: scroll;
  resize: none;
  width: 80%;
  height: 100%;
  padding: 10px;
  border-radius: 5px 0 0 5px;
  line-height: 1.5em;
}

.chat_textarea::-webkit-scrollbar {
  display: none;
}

.chat_textarea {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

.room_joined_msg_main {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.room_joined {
  display: flex;
  justify-content: center !important;
}

.room_joined_others_message_string {
  max-width: 80%;
  padding: 0px 10px;
  border-radius: 10px;
  color: gray;
  box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.15);
  word-break: break-word;
}

.dropdown-inner-container::-webkit-scrollbar {
  width: 10px !important;
  background: #5ec4f4;
  border-radius: 5px;
}
.dropdown-inner-container::-webkit-scrollbar-thumb {
  background: #000;
  border-radius: 10px;
}

.uploading-file-indicator {
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: bold;
}

.uploading-file-indicator .fa-spinner {
  font-size: 24px;
  margin-right: 10px;
}

.attachment-preview {
  display: flex;
  align-items: center;
}

.image-thumbnail {
  width: 100px;
  height: auto;
  border: 1px solid #ddd;
  box-shadow: 2px 2px 5px rgba(0,0,0,0.2);
}

.image-thumbnail:hover {
  transform: scale(1.1);
  cursor: pointer;
}

.file-icon {
  font-size: 24px;
}
.remove-attachment-button {
  background: none;
  border: none;
  cursor: pointer;
  padding: 5px;
  color: #ff0000;
  font-size: 30px;
}




.chat_textarea__suggestions{
  left: 21px !important;
    top: 6px !important;
  border-radius: 5px !important;
}

.chat_textarea__suggestions__list{
  height: 100%;
 
  border: solid 2px #26a3db !important;
  border-radius: 5px !important;

}
.chat_textarea__suggestions__list > li{
  border-bottom: 1px solid #26a3db !important;
  padding-bottom: 5px;
  padding: 4px !important;
  
}

.msgtyping{
  display: block;
  width: 100px;
  height: 40px;
  border-radius: 10px;
  margin-right: auto;
  /* margin-left: 5px; */
  display: flex;
  justify-content: center;
  align-items: center;
  padding:0 5px;
  border-top-left-radius: 0;


}
.circle {
  display: block;
  height: 10px;
  width: 10px;
  border-radius: 50%;
  background-color: #8d8d8d;
  margin: 3px;
}


.circle:nth-child(1){
  animation-delay: 0ms;
}
.circle:nth-child(2){
  animation-delay: 333ms;
}
.circle:nth-child(3){
  animation-delay: 666ms;
}
.scaling{
    animation: typing 1000ms ease-in-out infinite;
    animation-delay: 3600ms;
}

@keyframes typing {
  0% {
    transform: scale(1);
  }
  33% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.4);
  }
  100% {
    transform: scale(1);
  }
}
