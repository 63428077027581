.red {
    color: red;
  }
  
  .prog20 {
    accent-color: red;
  }
  
  .prog40 {
    accent-color: red;
  }
  
  .prog60 {
    accent-color: orange;
  }
  
  .prog80 {
    accent-color: orange;
  }
  
  .prog100 {
    accent-color: green;
  }
  
  .warning-alert {
    color: orange;
  }
  
  .green {
    color: green;
  }
  
  .alert-success {
    background-color: #d1e7dd;
  }

  .cont{
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    height: 100vh;
    
  }

  .cont > img{
    margin-top: 2rem;
  }

  .msg{
    margin-top: 5rem;
    font-size: 2rem;
  }