/*-----------------------------------------
------------- ( login-page )
-----------------------------------------*/

.alert-danger {
  color: #721c24;
  background-color: #f8d7da;
  border-color: #f5c6cb;
}

.alert {
  position: relative;
  padding: 0.75rem 1.25rem;
  margin-bottom: 1rem;
  border: 1px solid transparent;
  border-radius: 0.25rem;
}
.fade {
  transition: opacity .15s linear;
}

.login-page {
  background: #e9f6fb;
  position: relative;
  padding: 50px;
  height: 90vh;
  display: flex;
  align-items: center;
  justify-content: center;
}

.login-page .bg-box {
  background: url(../../assets/images/banner.svg) center;
  background-size: cover;
  height: 100%;
  width: 70%;
}
.login-page .form {
  z-index: 1;
  width: 30%;
  height: 100%;
  background: #fff;
  padding: 0 30px;
  position: relative;
}
.login-page .form img {
  display: block;
  max-width: 150px;
  margin: 0 auto 5px;
}
.login-page .form h4 {
  font-weight: 800;
}
.login-page .form h6 {
  margin-bottom: 0;
  font-weight: 600;
}
.login-page .form button {
  width: 100%;
  margin-bottom: 10px;
}
.login-page .form p a {
  margin-bottom: 0;
}
.login-page .form a {
  color: #1e3869;
}

.logout {
  cursor: pointer;
}

.logout-image {
  height: 15px;
  width: 15px;
}

.btn-link-a {
  color: #1e3869;
  cursor: pointer;
  font-size: 12px;
  line-height: 22px;
  display: inline;
  background-color: transparent;
  text-align: left;
  border: none;
  padding: 2px;
}
.btn-link-a:focus {
  outline: none;
}
@media (max-width: 1025px) {
  .login-page .bg-box {
    width: 65%;
  }
  .login-page .form {
    width: 35%;
  }
}
@media (max-width: 768px) {
  .login-page {
    height: auto;
    padding: 30px;
    flex-direction: column-reverse;
  }
  .login-page .bg-box {
    width: 100%;
    height: 250px;
  }
  .login-page .form {
    width: 100%;
  }
}
