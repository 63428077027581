/* width */
.sidebar-container > ::-webkit-scrollbar {
  width: 10px;
}

.sidebar-container > ::-webkit-scrollbar-track {
  background: #f1f1f1;
  border-radius: 10px;
}

.sidebar-container > ::-webkit-scrollbar-thumb {
  background: #888;
  border-radius: 10px;
  width: 8px;
}

.sidebar-container > ::-webkit-scrollbar-thumb:hover {
  background: #555;
}

.dropdown-inner-container.dropdown-menu.show {
  background: #9ddbf7;
  border-radius: 10px;
  padding: 2%;
}

.chat-container > ::-webkit-scrollbar {
  width: 10px;
}

.chat-container > ::-webkit-scrollbar-track {
  background: #f1f1f1;
  border-radius: 10px;
}

.chat-container > ::-webkit-scrollbar-thumb {
  background: #888;
  border-radius: 10px;
  width: 8px;
}

.chat-container > ::-webkit-scrollbar-thumb:hover {
  background: #555;
}

ul li {
  list-style-type: none;
}

.sidebar-container {
  max-width: 24%;
  flex: 0 0 24%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  box-shadow: 0 0px 5px 1px rgb(0 0 0 / 20%), 0px 0px 0px 0px rgb(0 0 0 / 19%);
  border: 0.5px solid lightblue;
  border-radius: 5px;
}

.dropdown-item {
  margin-bottom: 0px;
  font-weight: 600;
}

.dropdown-item:hover,
.dropdown-item:focus {
  color: #1e2125;
  background: transparent;
  border-radius: 5px;
}

.chat-content {
  height: 95%;
}

.alert-content {
  height: 100%;
  overflow-y: scroll;
  margin: 5px 0;
}

.participants-content {
  height: 100%;
  overflow-y: scroll;
  margin: 5px 0;
}

.participant-item {
  margin: 5px 5px;
}

.select-item {
  width: 20rem;
}

.select-item > button.menu-dropdown-name {
  background: white;
  color: black;
  border: 1px solid black;
  border-radius: 50px;
  font-size: 1rem;
}

.feedback_modal_container {
  border: none !important;
  width: 22rem !important;
  margin: auto;
}

.filters-container {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  margin: 10px 0 !important;
}

.feedback_modal_main {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.modal-body {
  margin: 0;
  padding: 0 !important;
}

.name_menu {
  max-height: 200px !important;
  overflow-y: scroll;
  width: 100%;
  font-size: 0.5rem;
}

::-webkit-scrollbar {
  width: 0;
  /* Remove scrollbar space */
}

.warning_temproommodal {
  color: red;
}

/* accordion styles
 */
.accordion_container {
  width: 100%;
  min-height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  padding: 0;
  
}

.card_container {
  width: 100%;
  height: 100%;
  padding-top: 10px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  -webkit-box-shadow: 0px 1px 5px 0px rgba(207, 207, 207, 1);
  -moz-box-shadow: 0px 1px 5px 0px rgba(207, 207, 207, 1);
  box-shadow: 0px 1px 5px 0px rgba(207, 207, 207, 1);
  resize: both;
}

.options_heading {
  background: transparent;
  border: none;
  font-size: 1rem;
  font-weight: 600;
  border-bottom: solid 3px #26a3db;
  padding: 0 !important;
  width: 100%;
  padding-bottom: 5px;
  margin-bottom: 5px;
  cursor: pointer;
}

.options_heading > p {
  margin: 0;
  display: flex;
  justify-content: center;
  align-items: center;
}

.options_heading > p > svg {
  width: 10px;
  height: 10px;
  margin-left: 10px;
}

.acc_collapse {
  height: 100% !important;
  overflow-y: scroll;
  width: 95% !important;
}

.acc_collapse::-webkit-scrollbar {
  width: 3px !important;
}

.participant_collapse,
.alert_collapse {
  height: 100%;
  width: 100% !important;
}

.card_alert_container,
.card_participant_container {
  height: 100% !important;
}

.__dbk__gutter.Dark {
  background-color: rgb(243, 242, 242) !important;
}

.__dbk__dragger.Vertical {
  background: rgb(204, 204, 204) !important;
}

.__dbk__child-wrapper {
  background: #f9f9fb !important;
}

.modal_cont {
  width: 450px;
  height: 350px;
  min-width: 300px;
  min-height: 300px;
  background: #fff;
  position: absolute;
  z-index: 2;
  box-shadow: 10px 20px 50px rgb(119, 119, 119);
  /* overflow: auto; */
  /* resize: both; */
  padding-top: 20px;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
}

.popup_header {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  border-bottom: solid 3px #26a3db;
}

.modal_cont p {
  font-size: 1rem;
  text-transform: uppercase;
  text-align: center;
  font-weight: bold;
  /* border-bottom: solid 3px #26a3db; */
}

.modal_cont::-webkit-scrollbar {
  width: 8px;
}

.modal_cont::-webkit-scrollbar-thumb {
  background: rgb(255, 255, 255);
}

.modal_cont::-webkit-scrollbar-thumb:hover {
  background: rgb(213, 213, 213);
}

.chat_btn,
.alert_btn,
.participant_btn {
  color: #111;
  /* position: absolute;
  top: 4%;
  left: 2%; */
  cursor: grab;
  border: none !important;
  margin-left: 10px;
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.chat_modal_container {
  top: 15%;
  left: 10%;
}

.alert_modal_container {
  top: 15%;
  left: 25%;
}

.participant_modal_container {
  top: 15%;
  left: 40%;
}

.cross_icon {
  color: #111;
  /* position: absolute;
  top: 4%;
  right: 2%; */
  cursor: pointer;
  border: none !important;
  font-size: 0.7rem !important;
  margin-right: 10px;
}


.save_layout_btn{
  background:#26a3db;
  color:#fff;
  border:solid 1px transparent;
  padding:5px 10px;
  border-radius: 5px;
  cursor: pointer;
  transition: 0.7s;
  margin-top: 10px;

}
.save_layout_btn:hover{
  opacity: 0.9;

}

.notifications_container{
  width: 150px;
  height: 130px;
  border: solid 1px #26a3db;
  border-radius: 10px;
  position: absolute;
  top: 23px;
  z-index: 1;
  background: #fff;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-size:0.9rem;
  font-family: sans-serif;
}

.notifications_container > label{
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  color: #111;
}
.notifications_container > label > input{
  width: 25%;
}

.notifications_container > button{
  background: #26a3db;
  color: #fff;
  font-size: 0.8rem;
  border: solid 1px transparent;
  padding: 8px 10px;
  border-radius: 5px;
  cursor: pointer;
}

