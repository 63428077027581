.chat-room {
  display: flex;
  overflow: hidden;
  height: 100vh;
  position: relative;
}
.sidebar-btn {
  position: absolute;
  top: 2px;
  right: 0px;
  box-shadow: 0 0px 5px 1px rgb(0 0 0 / 20%), 0px 0px 0px 0px rgb(0 0 0 / 19%);
  border: 0.5px solid lightblue;
  background: white;
  border-radius: 10px 0px 0px 10px;
  height: 35px;
  width: 45px;
  font-size: 18px;
  cursor: pointer;
}
.sidebar-btn-open {
  top: 2px;
  right: 22.6%;
  border-radius: 0px 10px 10px 0px;
  cursor: pointer;
}
.sidebar-btn-indication {
  border: 1.5px solid lightblue;
  font-size: 11px;
  /* top: 22px;
  right: 0px; */
  background: #ceedfb;
  border-radius: 50%;
  height: 15px;
  position: absolute;
  width: 15px;
  justify-content: center;
  align-items: center;
  display: flex;
  font-weight: bolder;
  color: red;
}
.vh100 {
  height: 100vh;
}

.vw100 {
  width: 100vw;
}

.full-container {
  display: flex;
  flex-direction: column;
}

.instructor-container,
.student-container {
  flex: 0 0 75%;
  height: 100vh;
}
.instructor-containerb {
  flex: 0 0 100%;
  height: 100vh;
}
.student-containerb {
  flex: 0 0 100%;
  padding: 1%;
}

.instructor-container {
  display: flex;
  flex-direction: column;
}

.student-container {
  padding: 20px;
  display: flex;
  flex-direction: column;
  height: 100%;
}

.pagination {
  display: flex;
  justify-content: space-around;
  align-items: center;
  width: 50%;
  text-align: center;
  margin: 0 auto;
  height: 5%;
  z-index: 1;
}
.students-grid {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  height: 74%;
  padding: 0 10px;
  margin: 0;
  position: relative;
}

.student-grid-row {
  width: 100%;
  height: 100%;
  margin: 0;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-evenly;
}

.student-grid-item {
  display: flex;
  flex-direction: column;
  height: 100%;
  align-items: center;
  justify-content: center;
}

.video-audio-container {
  background: #26a3dba6;
  border-radius: 10px;
  position: relative;
  height: 80%;
  width: 100%;
}

.page-number-item {
  width: 15px;
  height: 8px;
  border-radius: 50%;
  cursor: pointer;
}
.instructor-local-screen-participant-card-video-container {
  padding: 0 10px;
  width: 100%;
  display: flex;
  justify-content: stretch;
  height: 20%;
}

.h112 {
  height: 122px;
}
.w200 {
  width: 200px;
}

.large-video {
  height: 100%;
  width: 100%;
}

/* .white-border {
  border: 1px solid #ccc;
} */

.small-video-inner-one {
  flex: 25%;
  background: #26a3db52;
  border-radius: 5px;
}

.small-video-inner-two {
  flex: 25%;
  background: #26a3db52;
  border-radius: 5px;
}

.small-video {
  justify-content: flex-end;
  height: 25%;
  display: flex;
  position: absolute;
  right: 0;
  bottom: 0;
}

.small-student-video {
  height: 300px;
  width: 300px;
  position: absolute;
  right: 0;
  bottom: 0;
}

.student-screen-section {
  position: relative;
  height: 77vh;
}

/* .student-screen-inst-video {
   max-height: 50vh; 
}*/

.participant-card-container {
  display: flex;
  padding: 5px;
  align-items: center;
  /* justify-content: center; */
  position: relative;
  justify-content: space-between;
}

li.zero.student-grid-item.participants-7 {
  height: 100%;
}

/* Pramod's css */

/******************When one student loging css run add only (three_student_wrp) in ul tag*******************************/

.student_wrp_3 > li {
  margin: 0 auto;
  padding-left: 10px;
  padding-right: 10px;
}

.student_wrp_3 {
  justify-content: center;
}

.student_wrp_3 .video-audio-container {
  background: rgba(38, 163, 219, 0.6509803921568628);
  height: 82%;
  width: 100% !important;
}

.student_wrp_3 ul.control-icons {
  margin: 0;
  padding: 3px;
  align-items: center;
}

.student_wrp_3 .participant-card-container {
  display: flex;
  width: 100%;
  justify-content: space-between;
  margin: 0 auto;
  align-items: center !important;
}

.student_wrp_3 .participant-details-Instructor + div {
  flex: unset;
  margin: 0;
}

.student_wrp_3 .pravatar {
  width: 30px !important;
  min-width: unset !important;
  min-height: unset !important;
  max-width: unset !important;
  height: 30px !important;
}

.student_wrp_3 .participant-name {
  font-size: 12px !important;
}

.student_wrp_3 ul.control-icons > li {
  padding: 2px;
  line-height: initial;
}

.student_wrp_3 ul.control-icons > li svg,
.student_wrp_3 ul.control-icons > li img {
  width: 15px;
  padding: 0 1px;
  box-sizing: content-box;
}

.student_wrp_3 ul.control-icons > li:hover {
  border-radius: 3px;
}

/*and class in ul with (nine_student_wrp) in ul tag and also all user call in same ul with 9 use list */
.instructor-local-screen-video-inner-container {
  width: 100%;
  display: flex;
  height: 100%;
  justify-content: space-around;
  align-items: center;
}
.instructor-control-icon-container {
  position: relative;
  width: 40%;
  height: 90%;
}
.instructor-control-icon-container > .participant-card-container {
  position: absolute;
  bottom: 3px;
  width: 100%;
}

.instructor-local-screen-video-container {
  display: flex;
  justify-content: space-around;
  width: 100%;
  align-items: center;
  border-radius: 6px;
  padding-top: 5px;
  box-shadow: 0 0px 5px 1px rgb(0 0 0 / 20%), 0px 0px 0px 0px rgb(0 0 0 / 19%);
  border: 0.5px solid lightblue;
}

.admin_video_hight {
  width: 16%;
  height: 90%;
}
.un-verify_border {
  border: solid 2px red;
  border-radius: 10px;
}

.verify_border {
  border: solid 2px rgb(43, 255, 0);
  border-radius: 10px;
}


.other_person{
  border: solid 2px yellow ;
  border-radius: 10px;
}

.large-avatar {
  color: white;
  background-color: rgb(38, 163, 219);
  width: 100%;
  height: 100%;
  border-radius: 10px;
  position: relative;
  box-shadow: 0 0px 5px 1px rgb(0 0 0 / 20%), 0px 0px 0px 0px rgb(0 0 0 / 19%);
}

.Instructor-screen-avatar {
  color: white;
  background-color: rgb(38, 163, 219);
  width: 100%;
  height: 100%;
  border-radius: 10px;
  position: relative;
}

.Supervisor-screen-avatar,
.Admin-screen-avatar,
.Manager-screen-avatar,
.Director-screen-avatar {
  color: white;
  background-color: rgb(38, 163, 219);
  width: 100%;
  height: 100%;
  border-radius: 10px;
  position: relative;
}

.Student-screen-avatar {
  color: white;
  background-color: rgb(38, 163, 219);
  width: 100%;
  height: 100%;
  border-radius: 10px;
  position: relative;
}

h6.participant-name {
  font-size: 12px;
  line-height: initial;
}

.participant-card-container {
  padding: 0;
  align-items: center;
  padding-top: 5px;
  justify-content: space-evenly;
}
.pravatar.large_avater {
  color: white;
  background-color: rgb(38, 163, 219);
  width: 100%;
  height: 100%;
  border-radius: 10px;
}
li.zero.student-grid-item.participants-7
  .participant-card-container
  .audio-control.flex {
  padding-left: 0 !important;
}

ul.control-icons {
  padding: 5px;
  border-radius: 5px;
}
ul.control-icons svg,
ul.control-icons img {
  width: 15px !important;
}
.participant-name-type-Instructor {
  padding-bottom: 0;
}

.control-icons li {
  line-height: normal !important;
}

.student-screen-instructor-avatar {
  width: 100%;
  height: 95%;
  border-radius: 10px;
  position: relative;
  background: rgb(38, 163, 219);
  color: #ffffff;
  box-shadow: 0 0px 5px 1px rgb(0 0 0 / 20%), 0px 0px 0px 0px rgb(0 0 0 / 19%);
}
.alert-text {
  font-weight: bolder;
}
.alert-error-container {
  justify-content: center;
  display: flex;
  padding: 5%;
}
.alert-inner-container {
  justify-content: center;
  display: flex;
  flex-direction: column;
  width: fit-content;
}

.presenter {
  background-color: lightgrey;
  color: black;
  text-align: center;
  padding-right: 10px;
  border-radius: 3px;
}

.collab_container {
  width: 100%;
  display: flex;
  flex-direction: column;
  height: 100%;
}

.collab_Slides {
  width: 100%;
  height: 78%;
}

.collab_column {
  display: flex;
  justify-content: center;
  width: 100%;
  height: 15%;
  margin-top: 0.5%;
}

.collab_video {
  border: 1px solid black;
  margin: 0 1px;
  width: 100%;
  height: 85%;
  border-radius: 10px;
  position: relative;
}

.collab_avatar {
  width: 100%;
  color: white;
  background-color: rgb(38, 163, 219);
  height: 100%;
  border-radius: 10px;
  position: relative;
}

.collab_video_container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 16%;
}

.collab_peer_name {
  width: 100%;
  height: 10%;
  display: flex;
  justify-content: center;
}
