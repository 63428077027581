.content-grid {
  display: flex;
  max-width: 100%;
  justify-content: space-between;
  height: 95%;
  overflow: hidden;
}
/* .content-grid::-webkit-scrollbar {
  width: 5px;
}
.content-grid::-webkit-scrollbar-thumb {
  width: 5px;
  background: #ccc;
} */

@media only screen and (max-width: 4000px) {
  .content-grid {
    grid-template-columns: 6fr minmax(0, 3fr) 0fr;
  }
}

@media only screen and (max-width: 1080px) {
  .content-grid {
    grid-template-columns: minmax(1, 1fr);
  }
}

.content-grid .column-left {
  overflow-y: scroll;
  margin: 0 auto;
}

.content-grid .column-left .content-area {
  /* background: green; */
  max-width: 100%;
  margin: 20px 10px;
  position: relative;
}

nav .column-left {
  display: flex;
  align-items: center;
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  height: 100%;
}

nav .column-left .tab .bar {
  width: 100%;
  position: absolute;
  bottom: 0;
  border-bottom: 3px solid var(--primary-button-background);
}

.a_card.post {
  padding: 0;
}

.a_card {
  padding: 12px 16px 10px 16px;
  border-radius: max(0px, min(8px, calc((100vw - 4px - 100%) * 9999))) / 8px;
  box-shadow: 0 1px 2px var(--shadow-2);
  background: var(--surface-background);
  margin-bottom: 16px;
}
.input-popup-card {
  padding: 12px 16px 10px 16px;
  border-radius: 0px;
  box-shadow: none;
  margin: 20px;
  border-top: solid 1px #ccc;
}
.new-post-action {
  /* display: flex; */
}

.new-post-action .my-profile {
  width: 40px;
  height: 40px;
  border-radius: 1000px;
  border: 1px solid var(--media-inner-border);
  margin-right: 8px;
  cursor: pointer;
}

.new-post-action input {
  /* all: unset; */
  width: 100%;
  background: var(--comment-background);
  border-radius: 1000px;
  padding: 8px 12px;
}

.new-post-types {
  margin-top: 12px;
  border-top: solid 1px var(--secondary-button-background);
  display: flex;
  justify-content: space-evenly;
  font-size: 15px;
  font-weight: 600;
  color: var(--secondary-text);
  padding-top: 8px;
}

.new-post-types .post-type {
  padding: 8px;
  display: flex;
  align-items: center;
}

.new-post-types .post-type i {
  margin-right: 8px;
}

/* - */
.card.post {
  padding: 0;
}

.post-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 12px 16px 0px 16px;
}

.post-author-info {
  display: flex;
  align-items: flex-start;
}

.post-author-info img {
  width: 40px;
  height: 40px;
  border-radius: 1000px;
  margin-right: 8px;
}

.post-author-info .author-name {
  font-size: 15px;
  font-weight: 600;
  line-height: 20px;
  margin-right: 5.5px;
  color: #111;
  text-transform: uppercase;
}

.post-author-info .details {
  font-size: 13px;
  font-weight: 400;
  line-height: 16px;
}

.post-body {
  font-weight: 400;
  font-size: 15px;
  line-height: 20px;
  padding: 8px 16px 16px 16px;
}


.post-body a{
  width: 100%;
}
.post-image img {
  width: 100%;
  display: block;
}

.post-image .excerpt {
  width: 100%;
  background: var(--comment-background);
  padding: 12px 10px;
}

.post-image .excerpt label {
  font-size: 13px;
  font-weight: 400;
  line-height: 16px;
  line-height: 20px;
  text-transform: uppercase;
}

.post-image .excerpt h3 {
  color: var(--primary-text);
  font-size: 17px;
  font-weight: 600;
  line-height: 20px;
  /* margin: 5px 0; */
}

.post-image .excerpt span {
  line-height: 20px;
  font-weight: 400;
  font-size: 15px;
  display: block;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.post-info-icon-wrap {
  width: 28px;
  height: 28px;
  border-radius: 1000px;
  border: 1px solid var(--media-inner-border);
  display: flex;
  justify-content: center;
  align-items: center;
  background: var(--always-white);
  position: absolute;
  right: 14px;
  top: 0;
  transform: translateY(-50%);
}

.post-reactions {
  display: flex;
  justify-content: space-between;
  padding: 10px 16px;
  border-bottom: 1px solid var(--divider);
}

.post-reactions .reactions {
  display: flex;
  align-items: center;
}

.post-reactions .reactions .emojis {
  display: flex;
  flex-direction: row-reverse;
}

.post-reactions .reactions img {
  width: 22px;
  height: 22px;
  display: block;
  border: 2px solid var(--card-background);
  margin-left: -4px;
}

.post-reactions .reactions span {
  font-size: 15px;
  padding-left: 6px;
}

.post-reactions .comment-share {
  display: flex;
}

.post-reactions .comment-share span {
  font-size: 15px;
  font-weight: 400;
}

.post-reactions .comment-share .shares {
  margin-left: 7px;
}

.post-actions {
  display: flex;
  font-size: 15px;
  font-weight: 600;
  height: 44px;
  padding: 0 12px;
}

.post-actions .actions {
  width: 100%;
  display: flex;
  justify-content: space-evenly;
}

.post-actions .action {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  padding: 6px 2px;
}

.post-actions .action i,
.post-actions .action span {
  padding: 6px 4px;
}

.post-actions .interact-as {
  display: flex;
  align-items: center;
}

.post-actions .interact-as img {
  width: 20px;
  height: 20px;
  border-radius: 1000px;
  margin-right: 2px;
}

.like-icon {
  background-image: url("../../assets/images/icons5.png");
  width: 18px;
  height: 18px;
  background-position: 0 -172px;
  background-size: 25px 725px;
  display: inline-block;
  filter: var(--filter-secondary-icon);
}

.comment-icon {
  background-image: url("../../assets/images/icons5.png");
  width: 18px;
  height: 18px;
  background-position: 0 -172px;
  background-size: 25px 725px;
  display: inline-block;
  filter: var(--filter-secondary-icon);
}

/* Right Columns */
.content-grid .column-right {
  overflow-y: scroll;
}

.content-grid .column-right .separator {
  border-bottom: 1px solid var(--divider);
  margin: 0 16px;
}

.content-grid .column-right .contacts .header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-top: 20px;
  padding-bottom: 4px;
  padding-left: 16px;
  padding-right: 16px;
}

.content-grid .column-right .contacts .title {
  font-size: 17px;
  font-weight: 600;
  color: var(--secondary-text);
}

.content-grid .column-right .contacts .actions {
  display: flex;
  align-items: center;
}

.content-grid .column-right .contacts .actions i {
  margin-left: 24px;
}

.content-grid .column-right .contacts-list {
  /* padding: 8px 16px; */
  /* opacity: 0.5; */
}

.content-grid .column-right .contacts-item {
  display: flex;
  align-items: center;
  padding: 8px 0px;
}

.content-grid .column-right .contacts-item img {
  width: 36px;
  height: 36px;
  border-radius: 1000px;
  margin-right: 12px;
}

.content-grid .column-right .contacts-item span {
  font-size: 15px;
  font-weight: 500;
}

.contacts-list {
  height: 100%;
  overflow-y: scroll;
  padding: 12px 16px 10px 16px;
  border-radius: max(0px, min(8px, calc((100vw - 4px - 100%) * 9999))) / 8px;
  box-shadow: 0 1px 2px var(--shadow-2);
}

.topic-dd {
  /* background-color: rgb(38, 163, 219); */
  /* border-radius: 100px; */
  color: #111;
  border: none;
  cursor: pointer;
  background: #fff;
  margin: auto 0;
  font-size: 1rem;
  /* border-radius: 2px; */
  /* border: solid 1px #ccc; */
}

.post-type {
  display: flex;
  justify-content: center;
  align-self: center;
  border: solid 0.1px transparent;
  padding: 10px;
  border-radius: 10px;
  cursor: pointer;
  transition: 0.4s;
  border: solid 0.1px #c1c1c1;
}

.err-AC {
  color: red;
}

.userAC {
  font-size: 9px;
  color: rgb(38, 163, 219);
}

.verified-icon {
  width: 12px;
  height: 12px;
  background-image: url("../../assets/images/icons4.png");
  background-size: 73px 406px;
  display: inline-block;
  background-position: -34px -350px;
}

::-webkit-scrollbar {
  -webkit-appearance: none;
  width: 7px;
}

::-webkit-scrollbar-thumb {
  border-radius: 4px;
  background-color: rgb(169, 169, 169) !important;
  box-shadow: 0 0 1px rgba(255, 255, 255, 0.5);
}

.subUnsubBtn {
  margin-left: auto;
  margin-right: 0;
}

/* .btn-width {
  width: 110px;
} */

.post-date {
  font-style: italic;
  font-size: 10px;
}

textarea {
  resize: none;
}

.topic-name-list {
  font-size: 11px !important;
  font-weight: 500;
  color: #000000;
}

.subscribed {
  background-color: dimgray !important;
}

.mb-10 {
  margin-bottom: 15px;
}

.ml-5 {
  margin-left: 5px;
  color: #26a3db;
  cursor: pointer;
}

.prefix-img {
  cursor: pointer;
}

.dot_activity {
  height: 60px;
  width: 60px;
  background-color: #26a3db;
  border-radius: 50%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  position: fixed;
  cursor: move;
  border: 2px solid rgb(9, 126, 236);
}
.dot_inside_close {
  height: 20px;
  min-width: 15px;
  background-color: white;
  color: #26a3db;
  border-radius: 50%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  border: 2px solid rgb(9, 126, 236);
  position: relative;
  cursor: pointer;
}

.unread-post-count {
  border: 1.5px solid lightblue;
  font-size: 11px;
  background: #ceedfb;
  border-radius: 50%;
  height: 15px;
  position: absolute;
  width: 15px;
  justify-content: center;
  align-items: center;
  display: flex;
  font-weight: bolder;
  color: red;
  left: -3px;
  border-color: red;
}

.comment-icon-topics{
  font-size: 2.5rem;
  color: white;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items:center;
  position: relative;
  left:4px;
}

.unread-post-count p {
  font-size: 10px !important;
  font-weight: bold;
  line-height: 0 !important;
  margin: 0 !important;
}

.dot_inside_close p {
  font-size: 10px !important;
  font-weight: bold;
  line-height: 0 !important;
  margin: 0 !important;
}

.dot_inside_close:hover {
  background-color: #033c56;
  color: white;
  border: 2px solid white;
}

.minimize_icon,
.maxamize_icon {
  color: #111;
  position: absolute;
  top: 4%;
  right: 8%;
  cursor: pointer;
  border: none !important;
  font-size: 1.2rem !important;
}

.maxamize_icon {
  top: 4%;
  right: 6%;
}
.activity_modal {
  min-width: 850px !important;
  min-height: 400px !important;
}

/* .min_actions{
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: absolute;
  right: 10px;
  top: 10px;
  border: none;
  text-decoration: none;
  width: 30px;
}

.cross-icon{
  font-size: 0.7rem;
  font-family: 'Times New Roman', Times, serif;
  color: #111;
  font-weight: bold;
  border: none !important;
  cursor: pointer;

}

.minimize-icon{
  font-size: 20rem;
  border: none !important;
  color: #111;
  width: 10px;
  cursor: pointer;
  /* margin-left: 10px; */
/* } */

/* .double{
  box-shadow: 1px 1px 5px #ccc;
  font-size: 2rem;
  cursor: pointer;
} */

.post {
  position: relative;
}
.editTopicIcon {
  cursor: pointer;
  float: right;
  width: 50px !important;
  position: absolute;
  right: 28px;
  top: 10px;
}
.topic-type {
  cursor: pointer;
  float: right;
  width: 50px !important;
  position: absolute;
  right: 0;
  top: 10px;
}
.active_feed {
  color: #fff !important;
  font-weight: 600;
  background-color: #26a3db;
}
.active_feed:hover {
  background-color: #26a3db !important;
}

.activity_cont {
  background: #fff;
  position: absolute;

  z-index: 2;
  box-shadow: 10px 20px 50px rgb(119, 119, 119);

  border-radius: 10px;
}

.activity-headers {
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: flex-end;
  height: 100%;
  border-bottom: solid 1px #111;
}

.chat-picker {
  font-size: 1rem;
  color: #111;
  margin-left: 20px;
  cursor: grab;
}

.activity-heading {
  font-weight: 600;
  color: #111;
  font-size: 1.2rem;
  text-transform: uppercase;
}

.activity_cont::-webkit-scrollbar {
  background-color: transparent;
}

.activity_cont::-webkit-scrollbar-thumb {
  background: transparent;
}

.contacts-list::-webkit-scrollbar {
  width: 0;
}

.min_actions {
  display: flex;

  justify-content: space-between;

  align-items: center;

  width: 38px;

  margin-right: 20px;
}

.min_actions > p {
  font-size: 2rem;

  font-weight: 500;

  color: #111;

  cursor: pointer;
}

.cls-btn {
  font-size: 1rem !important;
}

.icon-close-tab {
  position: sticky;
  top: 5px;
  left: 0px;
  cursor: pointer;
  /* cursor: pointer;
    position: absolute;
    right: 10px;
    font-size: 35px;
    border:inset; */
}

.count-span {
  font-size: 1rem;
  font-family: "Courier New", Courier, monospace;
  color: #4c4c4c;
  /* margin-bottom: 10px; */
  padding-left: 10px;
}

.topic-input {
  margin-bottom: 0 !important;
  border: solid 1px transparent !important;
  border-radius: 2px !important;
}
.topic-input::placeholder {
  font-style: italic;
}

.description-input {
  margin-top: 15px;
}

.ql-container {
  height: 135px !important;
}

.post-popup-btn {
}
.post-header {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  z-index: 2;
}

.post-input-header {
  -webkit-box-shadow: 1px 10px 23px 0px rgba(232, 232, 232, 1);
  -moz-box-shadow: 1px 10px 23px 0px rgba(232, 232, 232, 1);
  box-shadow: 1px 10px 23px 0px rgba(232, 232, 232, 1);

  margin-bottom: 20px;
}

.imgg {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  margin-right: 20px;
}

.post-header > img {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  margin-right: 20px;
}

.post-header > button {
  width: 92%;
  background: #fff;
  border: solid 1px #ccc;
  border-radius: 5px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: 13px;
  cursor: pointer;
}

.post-header > button:hover {
  background: rgb(252, 252, 252);
}

.post-header > button > p {
  font-size: 1rem;
  font-family: sans-serif;
  color: #111;
  margin: auto 0 auto 10px;
}

.input-popup-label {
  font-size: 1rem;
  color: #111;
  margin-left: 20px;
  font-style: italic;
  color: grey;
}

.input-modal-popup-header {
  border-bottom: none !important;
}

.selector {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.selector_username {
  font-size: 1rem;
  color: #111;
}

.post-btn {
  width: 150px;
  height: 30px;
  font-size: 0.8rem !important;
  /* background-color: #26a3db;
  color: #fff;
  border: solid 1px #26a3db;
  border-radius: 5px;
  transition: 0.4s;
  cursor: pointer; */
}

/* .post-btn:hover{
  background-color: #005780;
} */

/* .disabled-btn{
  width: 150px;
  height: 30px;
  font-size: 0.8rem !important;
  background-color: #26a3db;
  color: #fff;
  border: solid 1px #26a3db;
  border-radius: 5px;
  transition: 0.4s;

  opacity: 0.5;
  cursor:not-allowed !important;
} */
.select-feeds-container {
  height: 415px;
  width: 200px;
  box-shadow: 0 1px 2px var(--shadow-2);
  border-radius: 10px;
}

.feeds-list-container {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  padding: 10px;
  overflow-y: scroll;
  height: 100%;
}

.feeds-list-container > li {
  margin: 5px 0;
  width: 100%;
  padding: 5px;
  color: #000000;
  /* font-weight: 500; */

  cursor: pointer;
  transition: 0.4s;

  border-radius: 5px;
}

.feeds-list-container > li:hover {
  background: #f7f7f7;
}

.edit-post-btn {
  color: #fff !important;
  border: none !important;
  font-size: 12px !important;
  padding: 8px 15px !important;
  border-radius: 5px !important;
  background: #26a3db !important;
  letter-spacing: 1px !important;
  cursor: pointer !important;
  width: 80px !important;
}

.edit-post-btn:hover {
  background: #1e3869;
}

.input-title-container {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  margin-bottom: 20px;
}

.drop_down_container {
  width: 25%;
  margin-right: 20px;
}

.inp_title_container {
  width: 75%;
}

.title-input {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  border: solid 1px #ccc;
}

.title-input > input {
  width: 80%;
}
.title-input > span {
  width: 20%;
  border-left: solid 1px #ccc;
}
.ac-info-container {
  display: flex;
  align-items: center;
}

@keyframes msg-popup {
  0% {
    opacity: 1;
  }

  50% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

.msg__popup {
  animation-name: msg-popup;
  animation-duration: 1.5s;
  animation-timing-function: ease-in-out;
  animation-iteration-count: infinite;
  color: red;
}

.list-title {
  text-transform: uppercase;
  color: #000 !important;
  font-weight: 500 !important;
}

.modal-close-btn {
  position: absolute;
  right: 10px;
  top: 10px;
  background: none;
  border: none;
  cursor: pointer;
}

.new-post-modal {
  display: flex;
  justify-content: center;
  font-size: 16px;
  font-weight: bold;
  color: red;
}

.type-catgory {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.type-catgory > input{
      margin-bottom: 0 !important;
}

.type-catgory:nth-child(1) {
  margin-right: 30px;
}

.urgent-tooltip {
  cursor: pointer;
  margin-left: 10px;
}


.lds-spinner {
  color: black;
  display: inline-block;
  position: relative;
  width: 80px;
  height: 80px;
}
.lds-spinner div {
  transform-origin: 40px 40px;
  animation: lds-spinner 1.2s linear infinite;
}
.lds-spinner div:after {
  content: " ";
  display: block;
  position: absolute;
  top: 3px;
  left: 37px;
  width: 6px;
  height: 18px;
  border-radius: 20%;
  background: black;
}
.lds-spinner div:nth-child(1) {
  transform: rotate(0deg);
  animation-delay: -1.1s;
}
.lds-spinner div:nth-child(2) {
  transform: rotate(30deg);
  animation-delay: -1s;
}
.lds-spinner div:nth-child(3) {
  transform: rotate(60deg);
  animation-delay: -0.9s;
}
.lds-spinner div:nth-child(4) {
  transform: rotate(90deg);
  animation-delay: -0.8s;
}
.lds-spinner div:nth-child(5) {
  transform: rotate(120deg);
  animation-delay: -0.7s;
}
.lds-spinner div:nth-child(6) {
  transform: rotate(150deg);
  animation-delay: -0.6s;
}
.lds-spinner div:nth-child(7) {
  transform: rotate(180deg);
  animation-delay: -0.5s;
}
.lds-spinner div:nth-child(8) {
  transform: rotate(210deg);
  animation-delay: -0.4s;
}
.lds-spinner div:nth-child(9) {
  transform: rotate(240deg);
  animation-delay: -0.3s;
}
.lds-spinner div:nth-child(10) {
  transform: rotate(270deg);
  animation-delay: -0.2s;
}
.lds-spinner div:nth-child(11) {
  transform: rotate(300deg);
  animation-delay: -0.1s;
}
.lds-spinner div:nth-child(12) {
  transform: rotate(330deg);
  animation-delay: 0s;
}
@keyframes lds-spinner {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
