.participant-item {
  display: flex;
  background: #ceedfb;
  padding: 5px;
  border-radius: 10px;
  height: 60px;
  cursor: pointer;
}

.participant-name {
  font-weight: 600;
  margin-bottom: 0;
  font-size: 14px;
  line-height: 24px;
  display: flex;
  justify-content: space-between;
  height: 65%;
}

.participant-name:hover {
  cursor: pointer;
}

.participant-role {
  font-size: 12px;
  line-height: 20px;
  display: flex;
  justify-content: space-between;
}
.name-role {
  width: 100%;
  margin-left: 10px;
}
.svg-btn {
  margin: 5%;
  cursor: pointer;
}
.dropdown > button#menu-dropdown {
  border: 0px;
  border-radius: 10px !important;
}
.dropdown-border {
  border-bottom: 1px solid;
}
.list-angry {
  color: lightblue;
}
.list-focussed {
  color: orange;
}
.list-happy {
  color: green;
}
.list-sad {
  color: blue;
}
.list-contempt {
  color: blue;
}
.list-thoughtful {
  color: blue;
}
.list-fearful {
  color: orange;
}
.list-fear {
  color: orange;
}
/* .list-disgusted {
  color: yellow;
} */
.list-neutral {
  color: grey;
}
.list-surprised {
  color: pink;
}
.list-surprise {
  color: pink;
}
.list-multiple {
  color: rgb(194, 15, 230);
}
.list-zero {
  color: red;
}

.list-facefalse{
  color: yellow;
}

.end-private-call {
  background-color: rgb(240, 110, 110);
  color: black;
  width: 3em;
  height: 3em;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.show_private_call {
  display: flex;
  background: #ceedfb;
  padding: 5px;
  border-radius: 10px;
  min-height: 60px;
  cursor: pointer;
}

.end-private-call:hover {
  background-color: red;
}

.show_private_call {
  display: flex;
  background: #ceedfb;
  padding: 5px;
  border-radius: 10px;
  min-height: 60px;
  cursor: pointer;
}

.btn_for_temp_room {
  background: rgb(38, 163, 219);
  color: white;
  width: 80%;
  padding: 10px;
  border-radius: 10px;
}

.btn_room_temp_container {
  width: 20%;
  display: flex;
  justify-content: center;
  position: fixed;
  bottom: 40px;
  right: 40px;
}

.mute_all {
  display: flex !important;
  justify-content: center;
  align-items: center;
  height: 2rem;
}

.time-span {
  font-size: 0.6vw;
  display: flex;
  align-items: baseline;
}
