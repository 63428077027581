#myHeader {
  top: 0;
  width: 100%;
  z-index: 99;
  background: #fff;
  -webkit-animation-duration: 1.5s;
  animation-duration: 1.5s;
  -webkit-animation-name: hdrfix;
  animation-name: hdrfix;
  box-shadow: 0 0 5px #aaaaaa;
  position: relative;
}

@-webkit-keyframes hdrfix {
  from {
    transform: translateY(-40px);
    opacity: 0;
  }
  to {
    transform: translateY(0);
    opacity: 1;
  }
}

.icon-img {
  width: 20px;
  height: 20px;
  margin-right: 7px;
  align-self: center;
}

.flex {
  display: flex;
}

.mr-4 {
  margin-right: 4px;
}

@keyframes hdrfix {
  from {
    transform: translateY(-40px);
    opacity: 0;
  }
  to {
    transform: translateY(0);
    opacity: 1;
  }
}

/*-----------------------------------------
  ------------- ( header )
  -----------------------------------------*/
header {
  border-bottom: 1px solid #e2e1e7;
}
header ul {
  display: flex;
  align-items: center;
}
header .left-box ul li {
  flex: 0 0 50%;
  padding: 0;
  text-transform: uppercase;
}
header .left-box .logo {
  padding-right: 15px;
  border-right: 1px solid #e2e1e7;
}

li.logo img {
  width: 100%;
}

header .left-box h4 {
  margin-bottom: 0;
  padding-left: 15px;
  font-weight: 600;
}
header .center-box a {
  display: flex;
  align-items: center;
}
header .center-box ul {
  justify-content: center;
}
header .center-box ul li a {
  margin: 0 5px;
}
header select {
  color: #747474;
  padding: 6px 12px;
  background: #f9f9fb;
  border-radius: 5px;
  outline: none;
  border: none;
}

header select:hover {
  background: #1e3869;
  color: #fff;
}
header .center-box a img {
  width: auto;
  height: 20px;
  margin-right: 7px;
}

header .right-box ul {
  padding: 7px 0;
  justify-content: flex-end;
  border-left: 1px solid #e2e1e7;
}
header .right-box ul li {
  padding: 0 10px;
}
header .right-box .profile {
  display: flex;
  align-items: center;
  justify-content: center;
}

header .right-box .dropdown-toggle {
  background: #26a3db;
  border: none;
  color: #fff;
  letter-spacing: 1px;
  font-size: 16px;
  padding: 5px 15px;
  border-radius: 5px;
}
header .right-box .dropdown-toggle:hover,
header .right-box .dropdown-toggle[aria-expanded="true"] {
  background: #1e3869;
  outline: none !important;
}
header .right-box .dropdown-menu {
  padding: 5px 10px;
}
header .right-box .dropdown-menu a {
  width: 100%;
}

header .right-box .logout:hover {
  opacity: 0.5;
}
header .right-box .logout{
  width: 2vw;
}
header .right-box .logout img{
  width:inherit !important;
}
@media (max-width: 1024px) {
  header .left-box h4 {
    font-size: 18px;
    line-height: 32px;
    padding-left: 10px;
  }
  header .left-box .logo {
    padding-right: 10px;
  }
  header .center-box ul li a {
    margin: 0 2px;
  }
  header select {
    padding: 3px 4px;
  }
  header .center-box a img {
    height: 16px;
    margin-right: 3px;
  }
  header .right-box ul li {
    padding: 0 5px;
  }
  header .right-box .dropdown-toggle {
    letter-spacing: 0.5px;
    font-size: 13px;
    padding: 5px 8px;
  }
  header .right-box .profile img {
    margin-right: 5px;
  }

}

@media (max-width: 991px) {
  header .col-md-4 {
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
    max-width: 100%;
  }
  .center-box {
    border-top: 1px solid #f1f1f1;
    border-bottom: 1px solid #f1f1f1;
    padding: 20px 0;
  }
  header .left-box h4 {
    font-size: 22px;
    line-height: 32px;
    padding-left: 0;
    text-align: center;
  }
  header .left-box .logo {
    text-align: center;
    padding-right: 0;
  }
  header .left-box .logo img {
    max-width: 230px;
  }
  header .center-box a img {
    height: 22px;
    margin-right: 5px;
  }
  header .center-box ul li a {
    margin: 0 10px;
  }
  header select {
    padding: 3px 10px;
  }
  header .right-box ul {
    padding: 12px 0;
    border-left: none;
    justify-content: center;
  }
  #myHeader[style="position: fixed;"] .col-md-4:nth-child(1),
  #myHeader[style="position: fixed;"] .col-md-4:nth-child(2) {
    display: none;
  }
  header .right-box .logout{
    width: 4vw;
  }
}

@media (max-width: 768px) {
  header .left-box h4 {
    font-size: 18px;
    line-height: 26px;
  }

  header .left-box .logo img {
    max-width: 160px;
  }
  header .right-box .profile img {
    margin-right: 10px;
  }
  header .center-box ul li a {
    margin: 0 5px;
  }
  header .right-box .dropdown-toggle {
    padding: 5px 12px;
  }
}

#menu-dropdown {
  cursor: pointer;
  position: relative;
}
#menu-dropdown > span{
  width: 13px;
  height: 13px;
  background: red;
  border-radius: 50%;
  position: absolute;
  right: -5px;
  top: -6px;
}

@media (max-width: 991px) {
  .ftop {
    flex-wrap: wrap !important;
  }
  .f1{
    width: 60% !important;
  }
  .f2{
    width: 40% !important;
  }
  .f3{
    width: 100% !important;
  }
}
.ftop{
  width:96vw !important;
}
.f1{
  width:35%;
  }
  .f2{
  width:35%;
  }
  .f3{
  width:25%;
  }
  .mr-4 select{
    width: 15vw;
  }

  .header-logo {
    height: 100px;
  }

  .verification_span{
    width: 10px;
    height: 10px;
    background-color: red;
    border-radius: 50%;
    margin-left: -15px;
    margin-right: 5px;
  }
  .verification_item{
    padding-right: 20px;
  }
  .dropdown-item{
    color: black;
    cursor: pointer;
  }

  .disabled-item{
    color:grey !important;
    cursor: no-drop;
    pointer-events: none;
  }