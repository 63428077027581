/* above for pen only */
.icon__signal-strength {
  display: inline-flex;
  align-items: flex-end;
  justify-content: flex-start;
  width: 25px;
  height: 15px;
}

.icon__signal-strength span {
  display: inline-block;
  width: 6px;
  margin-left: 2px;
  transform-origin: 100% 100%;
  background-color: rgba(197, 168, 168, 0.555);
  border-radius: 2px;
  animation-iteration-count: 1;
  animation-timing-function: cubic-bezier(0.17, 0.67, 0.42, 1.3);
  animation-fill-mode: both;
  animation-play-state: paused;
}

.icon__signal-strength .bar-1 {
  height: 25%;
  animation-duration: 0.3s;
  animation-delay: 0.1s;
}

.icon__signal-strength .bar-2 {
  height: 50%;
  animation-duration: 0.25s;
  animation-delay: 0.2s;
}
.icon__signal-strength .bar-3 {
  height: 75%;
  animation-duration: 0.2s;
  animation-delay: 0.3s;
}
.icon__signal-strength .bar-4 {
  height: 100%;
  animation-duration: 0.15s;
  animation-delay: 0.4s;
}

.icon__signal-strength .fill {
  background-color: rgb(74, 184, 235);
}
@keyframes signal-intro {
  from {
    opacity: 0.2;
    height: 2px;
  }
}
